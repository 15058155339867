import {Component, EventEmitter, HostBinding, OnInit, Output} from '@angular/core';
import {InComponentStatusOptions} from '../in.component';

@Component({
  selector: 'app-in-group-status-filter-ctrl',
  templateUrl: './in-group-status-filter-ctrl.component.html',
  styleUrls: ['./in-group-status-filter-ctrl.component.scss']
})
export class InGroupStatusFilterCtrlComponent implements OnInit {

  @Output() statusSelected = new EventEmitter();
  selectedStatus = InComponentStatusOptions.NONE;

  @HostBinding('class.selected') selected = false;

  constructor() { }

  ngOnInit(): void {
    this.selected = false;
  }

  selectDivergent(){
    this.selectStatus(InComponentStatusOptions.DIVERGENT);

  }
  selectSuccess(){
    this.selectStatus(InComponentStatusOptions.SUCCESS);
  }
  selectWaiting(){
    this.selectStatus(InComponentStatusOptions.WAITING);
  }

  private selectStatus(status){
    if(this.selectedStatus === status && status !== InComponentStatusOptions.NONE){
      this.selectedStatus = InComponentStatusOptions.NONE;
      this.selected = false;
    } else{
      this.selectedStatus = status;
      this.selected = true;
    }
    this.statusSelected.emit(this.selectedStatus);
  }
}
