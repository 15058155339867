import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  async toScreening(){
    await this.router.navigate(['screening']);
  }

  async toStocking(){
  }
}
