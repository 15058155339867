import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {DatePickerComponent} from '../../modals/date-picker/date-picker.component';
import {MatSelect} from '@angular/material/select';
import {debounceTime, first} from 'rxjs/operators';
import {InService} from '../../core/services/in.service';
import * as moment from 'moment';
import {Observable, Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {InDetailsBuyOrderModalComponent} from '../../modals/in-details-buy-order-modal/in-details-buy-order-modal.component';
import {InDetailsNfeModalComponent} from '../../modals/in-details-nfe-modal/in-details-nfe-modal.component';
import {Router} from '@angular/router';

export enum InComponentStatusOptions {
  SUCCESS = 'SUCCESS',
  WAITING = 'WAITING',
  DIVERGENT = 'DIVERGENT',
  NONE = 'NONE'
}

interface InComponentFilters {
  name: string;
  id: string;
  filter: (item) => boolean;
}

@Component({
  selector: 'app-in',
  templateUrl: './in.component.html',
  styleUrls: ['./in.component.scss']
})
export class InComponent implements OnInit, AfterViewInit, OnDestroy {

  allDocuments;
  allOrders;
  allNFes;

  filteredOrders;
  filteredNFes;

  searchTermFormControl: FormControl;
  searchTermSubscription: Subscription;
  searchTerm: string;

  selectedStatus: InComponentStatusOptions = InComponentStatusOptions.NONE;

  filters: InComponentFilters[] = [
    {
      name: 'hoje',
      id: 'TODAY',
      filter: (item) => {
        const firstTimeToday = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
        return moment(item.receivedAt).isSameOrAfter(firstTimeToday);
      }
    },
    {
      name: '7 dias',
      id: '7_DAYS',
      filter: (item) => {
        const time7daysAgo = moment().subtract(7, 'days').startOf('day');
        return moment(item.receivedAt).isSameOrAfter(time7daysAgo);
      }
    }
  ];
  selectedFilter;

  customFilterSelectedLabel: string;

  @ViewChild('select') select: MatSelect;

  selectSubscription;

  openOrderDetailsModal = (item, event) => {
    const dialogRef = this.dialog.open(InDetailsBuyOrderModalComponent, {
      panelClass: 'buy-order-modal',
      data: item
    });
    dialogRef.afterClosed().pipe(first()).subscribe( res => {
      console.log('close');
    });
  }

  openNfeDetailsModal = (item, event) => {
    const dialogRef = this.dialog.open(InDetailsNfeModalComponent, {
    });
    dialogRef.afterClosed().pipe(first()).subscribe( res => {
      console.log('close');
    });
  }

  constructor(public dialog: MatDialog, public inService: InService, public ref: ChangeDetectorRef,
              private router: Router) {
    this.selectedFilter = this.filters[0];

    this.searchTermFormControl = new FormControl();


    inService.getDocuments().subscribe((values) => {
      this.allDocuments = values;
      this.allOrders = this.allDocuments.filter((item) => item.type === 'ORDER');
      this.allNFes = this.allDocuments.filter((item) => item.type === 'NFE');

      this.filteredOrders = [...this.allOrders];
      this.filteredNFes = [...this.allNFes];

      this.filter();
      this.order();

    });
  }

  ngOnInit(): void {
    this.searchTermSubscription = this.searchTermFormControl.valueChanges.pipe(debounceTime(500))
      .subscribe(newValue => {
        this.searchTerm = newValue;
        this.filter();
        this.order();
      });
  }

  ngOnDestroy(): void {
    this.selectSubscription?.unsubscribe();
    this.searchTermSubscription?.unsubscribe();
  }

  ngAfterViewInit(){
    this.selectSubscription = this.select.optionSelectionChanges.subscribe(event => {
      if (event.isUserInput){
        const value = event.source.value;

        this.customFilterSelectedLabel = null;
        if(value === 'CUSTOM' || value === 'CURRENT_CUSTOM'){
          this.openCustomDateFilterModal();
        } else{
          this.selectedFilter = this.filters.find((f) => f.id === value);
          this.filter();
          this.order();
        }
      }
    });
  }

  statusSelected(status){
    this.selectedStatus = status;
    this.filter();
    this.order();
  }

  filter(){

    let orders = [...this.allOrders];
    let nfe = [...this.allNFes];

    if(this.searchTerm){
      orders = orders.filter(o => {
        return JSON.stringify(o).toUpperCase().includes(this.searchTerm?.toUpperCase());
      });
      nfe = nfe.filter(o => {
        return JSON.stringify(o).toUpperCase().includes(this.searchTerm?.toUpperCase());
      });
    }

    this.filteredOrders =  this.filterDocuments(orders);
    this.filteredNFes = this.filterDocuments(nfe);
  }

  order(){
    this.filteredNFes = this.filteredNFes.sort((a, b) => {
      const weights = {
        BLOCKED: 1,
        DIVERGENT: 2,
        WAITING_CORRECTION: 3,
        WAITING_COMPARISON: 4,
        SUCCESS: 5
      };

      if (weights[a.status] === weights[b.status]){
        return weights[a.status];
      }
      else if (weights[a.status] < weights[b.status]){
        return -1 * weights[a.status];
      }
      else if (weights[a.status] > weights[b.status]){
        return 1 * weights[a.status];
      } else{
        return 999999999;
      }
    });
  }

  private filterDocuments(allDocuments){
    let documents = [];
    if (this.selectedStatus !== InComponentStatusOptions.NONE){
      documents = allDocuments.filter(o => o.status === this.selectedStatus);
    } else{
      documents = [...allDocuments];
    }
    return documents.filter(this.selectedFilter.filter);
  }



  openCustomDateFilterModal(){
    const dialogRef = this.dialog.open(DatePickerComponent, {
    });
    dialogRef.afterClosed().pipe(first()).subscribe( res => {
      this.customFilterSelectedLabel = `${res.start.toString()} - ${res.end.toString()}`;
      this.selectedFilter = {
        id: 'CUSTOMIZADO',
        name: 'yo',
        filter: (item) => {
          const start = moment(res.start).set({hour: 0, minute: 0, second: 0, millisecond: 0});
          const end = moment(res.end).set({hour: 23, minute: 59, second: 59, millisecond: 0});
          return moment(item.receivedAt).isBetween(start, end);
        }
      };
      this.filter();
      this.order();
    });
  }

  async toHomePage() {
    await this.router.navigate(['/dashboard']);
  }

  async toScreening() {
    await this.router.navigate(['/screening']);
  }
}
