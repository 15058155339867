import { Injectable } from '@angular/core';
import {AuthDataSourceService} from '../data-sources/auth-data-source.service';
import {Session, SessionStorageService} from './session-storage.service';
import {BehaviorSubject} from 'rxjs';
import jwtDecode from 'jwt-decode';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private isLoggedIn = false;

  constructor(private authDataSourceService: AuthDataSourceService,
              private authService: AuthService,
              private sessionStorageService: SessionStorageService) { }

  async login(user, pass, device) {
    return this.authDataSourceService.getToken(user, pass, device).then(res => {
      const {exp}: any = jwtDecode(res.accessToken);
      const session: Session = {...res, exp};
      this.sessionStorageService.saveSession(session);
      this.isLoggedIn = true;
      this.authService.isLoggedIn$.next(this.isLoggedIn);
    }).catch(err => {
      this.isLoggedIn = false;
      this.authService.isLoggedIn$.next(this.isLoggedIn);
      return err;
    });
  }

  async logout() {
    this.sessionStorageService.clearSessionStorage();
    this.isLoggedIn = false;
    this.authService.isLoggedIn$.next(this.isLoggedIn);
  }
}
