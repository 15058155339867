import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { InComponent } from './pages/in/in.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';

import { HeaderComponent } from './shared/header/header.component';
import { ExampleComponent } from './pages/example/example.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { InGroupComponent } from './pages/in/in-group/in-group.component';
import { InGroupBuyOrderItemComponent } from './pages/in/in-group-buy-order-item/in-group-buy-order-item.component';
import { InGroupBuyOrderHeaderComponent } from './pages/in/in-group-buy-order-header/in-group-buy-order-header.component';
import {DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY, MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import { DatePickerComponent } from './modals/date-picker/date-picker.component';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import { InGroupNfeHeaderComponent } from './pages/in/in-group-nfe-header/in-group-nfe-header.component';
import { InGroupNfeItemComponent } from './pages/in/in-group-nfe-item/in-group-nfe-item.component';
import { InGroupStatusFilterCtrlComponent } from './pages/in/in-group-status-filter-ctrl/in-group-status-filter-ctrl.component';
import { InDetailsBuyOrderModalComponent } from './modals/in-details-buy-order-modal/in-details-buy-order-modal.component';
import {MatTabsModule} from '@angular/material/tabs';
import { InDetailsBuyOrderComponent } from './modals/in-details-buy-order-modal/in-details-buy-order/in-details-buy-order.component';
import { InDetailsCommentsComponent } from './modals/in-details-buy-order-modal/in-details-comments/in-details-comments.component';
import { InDetailsNfeModalComponent } from './modals/in-details-nfe-modal/in-details-nfe-modal.component';
import {AuthTokenInterceptor} from './core/interceptors/auth-token.interceptor';
import {SideColorCardComponent} from './shared/side-color-card/side-color-card.component';
import { PrevNextButtonComponent } from './shared/prev-next-button/prev-next-button.component';
import { ScreeningComponent } from './pages/screening/screening.component';
import { InspectionComponent } from './pages/inspection/inspection.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderModalComponent } from './modals/shared/header-modal/header-modal.component';
import { StatusHeaderModalComponent } from './modals/shared/status-header-modal/status-header-modal.component';
import { ProviderContactComponent } from './modals/shared/provider-contact/provider-contact.component';
import { InfoOcOrNfeComponent } from './modals/shared/info-oc-or-nfe/info-oc-or-nfe.component';
import {RefreshTokenInterceptor} from './core/interceptors/refresh-token.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InComponent,
    NotFoundComponent,
    HeaderComponent,
    ExampleComponent,
    InGroupComponent,
    InGroupBuyOrderItemComponent,
    InGroupBuyOrderHeaderComponent,
    DatePickerComponent,
    InGroupNfeHeaderComponent,
    InGroupNfeItemComponent,
    InGroupStatusFilterCtrlComponent,
    InDetailsBuyOrderComponent,
    InDetailsBuyOrderComponent,
    InDetailsCommentsComponent,
    InDetailsBuyOrderModalComponent,
    InDetailsNfeModalComponent,
    SideColorCardComponent,
    PrevNextButtonComponent,
    ScreeningComponent,
    InspectionComponent,
    DashboardComponent,
    HeaderModalComponent,
    StatusHeaderModalComponent,
    ProviderContactComponent,
    InfoOcOrNfeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDialogModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatTabsModule
  ],
  providers: [
      {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
      {
        provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
        useClass: DefaultMatCalendarRangeStrategy,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthTokenInterceptor,
        multi: true
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
