<div class="up-modal">
  <div class="left">
    <div class="column">
      <div *ngFor="let info of infos">
        <app-info-oc-or-nfe [label]="info.label" [value]="info.value"></app-info-oc-or-nfe>
      </div>
    </div>
  </div>

  <div class="right">
    <div class="download-button">
      <button mat-button>
        <i class="uil uil-file-download"></i>
        <span class="text">BAIXAR OC EM XML</span>
      </button>
    </div>
    <app-provider-contact [provider]="{name: 'João dos Santos',
                                       phone: '(41) 99895-0001',
                                       email: 'joaodossantos@empresa.com'}">
    </app-provider-contact>
  </div>
</div>

<div class="down-modal">
  <div class="header">
    <div class="product-code column">
      Código de produto
    </div>
    <div class="provider-code column">
      Código do fornecedor
    </div>
    <div class="description column">
      Descrição
    </div>
    <div class="packaging column">
      Embalagem
    </div>
    <div class="unit-of-measurement column">
      UM
    </div>
    <div class="quantity column">
      QTD
    </div>
  </div>
  <div class="item">
    <div class="product-code column">
      A00001
    </div>
    <div class="provider-code column">
      00000
    </div>
    <div class="description column">
      Produto 1 - Celular Smartphone Galaxy S10
    </div>
    <div class="packaging column">
      Pallet
    </div>
    <div class="unit-of-measurement column">
      Un
    </div>
    <div class="quantity column">
      101
    </div>
  </div>
</div>
