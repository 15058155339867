import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-screening',
  templateUrl: './screening.component.html',
  styleUrls: ['./screening.component.scss']
})
export class ScreeningComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  async toIn(){
    await this.router.navigate(['in']);
  }

  async toInspection(){
    await this.router.navigate(['inspection']);
  }

}
