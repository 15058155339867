import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {InComponent} from './pages/in/in.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {ExampleComponent} from './pages/example/example.component';
import {ScreeningComponent} from './pages/screening/screening.component';
import {InspectionComponent} from './pages/inspection/inspection.component';
import {DashboardComponent} from './dashboard/dashboard.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'in', component: InComponent },
  { path: 'screening', component: ScreeningComponent },
  { path: 'inspection', component: InspectionComponent },
  { path: 'example', component: ExampleComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
