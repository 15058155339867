import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-prev-next-button',
  templateUrl: './prev-next-button.component.html',
  styleUrls: ['./prev-next-button.component.scss']
})
export class PrevNextButtonComponent implements OnInit {

  @Input() direction: string;
  @Input() icon: string;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }
}
