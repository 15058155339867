import {Component, HostBinding, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-in-group-buy-order-item',
  templateUrl: './in-group-buy-order-item.component.html',
  styleUrls: ['./in-group-buy-order-item.component.scss']
})
export class InGroupBuyOrderItemComponent implements OnInit {

  @Input() order;
  @HostBinding('class.isOdd') @Input() isOdd;
  date;


  constructor() { }

  ngOnInit(): void {
    this.date = moment(this.order.receivedAt).format('DD/MM/YYYY - hh:mm');
  }
}
