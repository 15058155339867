import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-in-details-buy-order',
  templateUrl: './in-details-buy-order.component.html',
  styleUrls: ['./in-details-buy-order.component.scss']
})
export class InDetailsBuyOrderComponent implements OnInit {

  @Input() order;

  receiveDate;
  infos;

  constructor() { }

  ngOnInit(): void {
    this.receiveDate = moment(this.order.receivedAt).format('DD/MM/YYYY - hh:mm');
    this.infos = [
      {label: 'Nº OC', value: this.order.oc},
      {label: 'Razão Social', value: this.order.corporateName},
      {label: 'Endereço', value: 'Av. Paulista, 558 - São Paulo-SP | 82937-000'},
      {label: 'Data/Hora de Emissão', value: this.receiveDate},
      {label: 'CPF/CNPJ Fornecedor', value: this.order.document},
    ];
  }

}
