import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-in-details-nfe-modal',
  templateUrl: './in-details-nfe-modal.component.html',
  styleUrls: ['./in-details-nfe-modal.component.scss']
})
export class InDetailsNfeModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
