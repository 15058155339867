<app-side-color-card color="in">
  <div content>test</div>
</app-side-color-card>

<div class="col">
  <div class="row">
    <div class="col">
      <div>
        <button mat-raised-button class="primary-1">teste</button>
        <button mat-raised-button class="primary-2">teste</button>
        <button mat-raised-button class="primary-3">teste</button>
        <button mat-raised-button class="primary-4">teste</button>
      </div>

      <div>
        <button mat-flat-button class="primary-1">teste</button>
        <button mat-flat-button class="primary-2">teste</button>
        <button mat-flat-button class="primary-3">teste</button>
        <button mat-flat-button class="primary-4">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="primary-1 full">teste</button>
        <button mat-raised-button class="primary-2 full">teste</button>
        <button mat-raised-button class="primary-3 full">teste</button>
        <button mat-raised-button class="primary-4 full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="primary-1">teste</button>
        <button mat-raised-button disabled class="primary-2">teste</button>
        <button mat-raised-button disabled class="primary-3">teste</button>
        <button mat-raised-button disabled class="primary-4">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="primary-1">teste</button>
        <button mat-stroked-button class="primary-2">teste</button>
        <button mat-stroked-button class="primary-3">teste</button>
        <button mat-stroked-button class="primary-4">teste</button>
      </div>
    </div>

    <div class="col">
      <div>
        <button mat-raised-button class="secondary-1">teste</button>
        <button mat-raised-button class="secondary-2">teste</button>
        <button mat-raised-button class="secondary-3">teste</button>
        <button mat-raised-button class="secondary-4">teste</button>
      </div>

      <div>
        <button mat-flat-button class="secondary-1">teste</button>
        <button mat-flat-button class="secondary-2">teste</button>
        <button mat-flat-button class="secondary-3">teste</button>
        <button mat-flat-button class="secondary-4">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="secondary-1 full">teste</button>
        <button mat-raised-button class="secondary-2 full">teste</button>
        <button mat-raised-button class="secondary-3 full">teste</button>
        <button mat-raised-button class="secondary-4 full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="secondary-1">teste</button>
        <button mat-raised-button disabled class="secondary-2">teste</button>
        <button mat-raised-button disabled class="secondary-3">teste</button>
        <button mat-raised-button disabled class="secondary-4">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="secondary-1">teste</button>
        <button mat-stroked-button class="secondary-2">teste</button>
        <button mat-stroked-button class="secondary-3">teste</button>
        <button mat-stroked-button class="secondary-4">teste</button>
      </div>
    </div>

    <div class="col">
      <div>
        <button mat-raised-button class="gray-1">teste</button>
        <button mat-raised-button class="gray-2">teste</button>
        <button mat-raised-button class="gray-3">teste</button>
        <button mat-raised-button class="gray-4">teste</button>
      </div>

      <div>
        <button mat-flat-button class="gray-1">teste</button>
        <button mat-flat-button class="gray-2">teste</button>
        <button mat-flat-button class="gray-3">teste</button>
        <button mat-flat-button class="gray-4">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="gray-1 full">teste</button>
        <button mat-raised-button class="gray-2 full">teste</button>
        <button mat-raised-button class="gray-3 full">teste</button>
        <button mat-raised-button class="gray-4 full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="gray-1">teste</button>
        <button mat-raised-button disabled class="gray-2">teste</button>
        <button mat-raised-button disabled class="gray-3">teste</button>
        <button mat-raised-button disabled class="gray-4">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="gray-1">teste</button>
        <button mat-stroked-button class="gray-2">teste</button>
        <button mat-stroked-button class="gray-3">teste</button>
        <button mat-stroked-button class="gray-4">teste</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div>
        <button mat-raised-button class="alert">teste</button>
      </div>

      <div>
        <button mat-flat-button class="alert">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="alert full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="alert">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="alert">teste</button>
      </div>
    </div>

    <div class="col">
      <div>
        <button mat-raised-button class="warning">teste</button>
      </div>

      <div>
        <button mat-flat-button class="warning">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="warning full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="warning">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="warning">teste</button>
      </div>
    </div>

    <div class="col">
      <div>
        <button mat-raised-button class="success">teste</button>
      </div>

      <div>
        <button mat-flat-button class="success">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="success full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="success">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="success">teste</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div>
        <button mat-raised-button class="in">teste</button>
      </div>

      <div>
        <button mat-flat-button class="in">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="in full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="in">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="in">teste</button>
      </div>
    </div>

    <div class="col">
      <div>
        <button mat-raised-button class="stocking">teste</button>
      </div>

      <div>
        <button mat-flat-button class="stocking">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="stocking full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="stocking">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="stocking">teste</button>
      </div>
    </div>

    <div class="col">
      <div>
        <button mat-raised-button class="picking">teste</button>
      </div>

      <div>
        <button mat-flat-button class="picking">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="picking full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="picking">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="picking">teste</button>
      </div>
    </div>

    <div class="col">
      <div>
        <button mat-raised-button class="out">teste</button>
      </div>

      <div>
        <button mat-flat-button class="out">teste</button>
      </div>

      <div style="width: 100%">
        <button mat-raised-button class="out full">teste</button>
      </div>

      <div>
        <button mat-raised-button disabled class="out">teste</button>
      </div>

      <div>
        <button mat-stroked-button class="out">teste</button>
      </div>
    </div>
  </div>
</div>
