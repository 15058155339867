import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InService {

  constructor() { }

  getDocuments(){
    return new Observable((observable) =>
     observable.next([
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT55", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629828705000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT54", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629828705000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1628798147000000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629828705000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629828705000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629828705000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT01", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629828705000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT02", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629828705000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1628798147000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1628798147000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1628798147000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1628798147000},
      {type: "ORDER", status: "WAITING", stage:"SEND", commentsQty:1, oc:"CT50", document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt: 1629210038000},
       {type: "NFE", status: "DIVERGENT", stage:"RECEIVED", commentsQty:1,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT60", orderReceivedAt: 1628798147000
       },
       {type: "NFE", status: "WAITING_COMPARISON", stage:"RECEIVED", commentsQty:0,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT53", orderReceivedAt: 1628798147000
       },
       {type: "NFE", status: "SUCCESS", stage:"RECEIVED", commentsQty:1,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT12", orderReceivedAt: 1629210038000
       },
       {type: "NFE", status: "SUCCESS", stage:"RECEIVED", commentsQty:1,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT12", orderReceivedAt: 1629210038000
       },
       {type: "NFE", status: "SUCCESS", stage:"RECEIVED", commentsQty:1,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT12", orderReceivedAt: 1629210038000
       },
       {type: "NFE", status: "BLOCKED", stage:"RECEIVED", commentsQty:1,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT121342", orderReceivedAt: 1629210038000
       },
       {type: "NFE", status: "DIVERGENT", stage:"RECEIVED", commentsQty:1,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT60", orderReceivedAt: 1628798147000
       },
       {type: "NFE", status: "WAITING_CORRECTION", stage:"RECEIVED", commentsQty:0,
         document: "00.000.000/0001-00", corporateName: "XPTO ACessórios", receivedAt:1629828705000,
         orderOc: "CT53", orderReceivedAt: 1628798147000
       },
    ])
    );
  }
}
