import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

export interface TokenResponse {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthDataSourceService {

  constructor(private httpClient: HttpClient) { }

  async getToken(user, pass, device): Promise<TokenResponse> {
    const body = {
      username: user,
      password: pass,
      currentDevice: device
    };
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.post(environment.baseUrl + '/api/v1/auth/token', body, { headers }).toPromise<any>();
  }

  refreshToken(refreshToken){
    const headers = {
      'Content-Type': 'application/json'
    };
    const body = {
      refreshToken
    };
    return this.httpClient.post(environment.baseUrl + '/api/v1/auth/refresh-token', body, { headers });
  }


}

