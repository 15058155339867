<div class="prev-next">
  <div class="align">
    <app-prev-next-button (click)="toHomePage()" [direction]="'left'" [icon]="'estate'"></app-prev-next-button>
  </div>
  <h1>Documentos de entrada / Triagem</h1>
  <div class="align">
    <app-prev-next-button (click)="toScreening()" [direction]="'right'" [label]="'triagem'"></app-prev-next-button>
  </div>
</div>

<div class="ctrl-bar">

  <input matInput [formControl]="searchTermFormControl">

  <mat-select #select style="background-color: #0F8A8D" [value]="selectedFilter?.id">
    <mat-option *ngFor="let filter of filters" [value]="filter.id">{{filter.name}}</mat-option>
    <mat-option [value]="'CUSTOM'" [disabled]="customFilterSelectedLabel">{{customFilterSelectedLabel ? customFilterSelectedLabel : "customizado"}}</mat-option>
    <mat-option [value]="'CUSTOM'" *ngIf="customFilterSelectedLabel" >{{!customFilterSelectedLabel ? customFilterSelectedLabel : "customizado"}}</mat-option>
  </mat-select>

  <app-in-group-status-filter-ctrl (statusSelected)="statusSelected($event)"></app-in-group-status-filter-ctrl>
</div>

<div class="content">

  <app-side-color-card color="in">
    <app-in-group
      content
      [title]="'ordens de compra'"
      [type]="'ORDER'"
      [windowSize]="5"
      [itemClick]="openOrderDetailsModal"
      [documents]="filteredOrders">
      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-in-group-buy-order-header header></app-in-group-buy-order-header>
    </app-in-group>
  </app-side-color-card>


  <app-side-color-card color="in">
    <app-in-group
      content
      [title]="'notas fiscais recebidas (xml)'"
      [type]="'NFE'"
      [windowSize]="5"
      [itemClick]="openNfeDetailsModal"
      [documents]="filteredNFes">
      <div empty>
        NÃO EXISTEM ITENS NESSA ETAPA NESSE MOMENTO.
      </div>
      <app-in-group-nfe-header header></app-in-group-nfe-header>
    </app-in-group>
  </app-side-color-card>
</div>
