import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {LoginService} from '../services/login.service';
import {Session, SessionStorageService} from '../services/session-storage.service';
import {catchError, switchMap, take, filter} from 'rxjs/operators';
import {AuthDataSourceService} from '../data-sources/auth-data-source.service';
import {AuthService} from '../services/auth.service';
import jwtDecode from 'jwt-decode';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private loginService: LoginService,
              private authDataSourceService: AuthDataSourceService,
              private authService: AuthService,
              private sessionStorageService: SessionStorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const isLoggedIn = this.authService.isLoggedIn();
    console.log("isLoggedIn", isLoggedIn)
    return next.handle(req).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && isLoggedIn && error.status === 401) {
        console.log("handle401Error")
        return this.handle401Error(req, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const currentSession: Session = this.sessionStorageService.getSession();
      return this.authDataSourceService.refreshToken(currentSession.refreshToken).pipe(
        switchMap((response: any) => {
          console.log(response);
          this.isRefreshing = false;
          const newSession: Session = {...currentSession};
          const {exp}: any = jwtDecode(response.accessToken);

          newSession.accessToken = response.accessToken;
          newSession.refreshToken = response.refreshToken;
          newSession.exp = exp;
          this.sessionStorageService.saveSession(newSession);

          this.refreshTokenSubject.next(response.accessToken);
          return next.handle(request);
        }));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(request);
        }));
    }
  }
}
