<div class="summary">
  <div class="value">
    {{quantity}}
  </div>
  <div class="title">
    {{title | uppercase}}
  </div>
</div>
<div class="content">
  <div class="list" *ngIf="!isEmpty">
    <ng-content select="[header]"></ng-content>
    <ng-container [ngSwitch]="type">
      <ng-container *ngSwitchCase="'ORDER'">
        <app-in-group-buy-order-item *ngFor="let document of displayedDocuments; let odd = odd"
                                     [order]="document"
                                     [isOdd]="odd"
                                     (click)="itemClickHandler(document, $event)">
        </app-in-group-buy-order-item>
      </ng-container>
      <ng-container *ngSwitchCase="'NFE'">
        <app-in-group-nfe-item *ngFor="let document of displayedDocuments; let odd = odd"
                               [nfe]="document"
                               [isOdd]="odd"
                               (click)="itemClickHandler(document, $event)">
        </app-in-group-nfe-item>
      </ng-container>
    </ng-container>
  </div>

  <div class="empty" *ngIf="isEmpty">
    <ng-content select="[empty]"></ng-content>
  </div>

  <div class="more" *ngIf="!displayAll && displayShowMoreToggle" (click)="toggle($event)">
    <i class="uil uil-angle-down"></i> ver mais
  </div>
  <div class="less" *ngIf="displayAll && displayShowMoreToggle" (click)="toggle($event)">
    <i class="uil uil-angle-up"></i> ver menos
  </div>
</div>
