<h4>CONTATO DO FORNECEDOR</h4>
<div class="provider-contact">
  <div class="name column">
    <small>Nome</small>
    <p>{{ provider.name }}</p>
  </div>
  <div class="phone column">
    <small>Telefone</small>
    <p>{{ provider.phone }}</p>
  </div>
  <div class="mail column three">
    <small>Email</small>
    <p>{{ provider.email }}</p>
  </div>
</div>
