import {Component, OnInit} from '@angular/core';
import {LoginService} from './core/services/login.service';
import {AuthService} from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'pda001-quantum-fe';
  isLogged = false;

  constructor(private authService: AuthService) {
  }

  ngOnInit(): void {
    this.authService.isLoggedIn$.subscribe(
      (isLogged) => {
        this.isLogged = isLogged;
      }
    );
  }
}
