import {Component, Input, OnInit} from '@angular/core';
import {LoginService} from '../../core/services/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() user: string;

  constructor(private loginService: LoginService,
              private router: Router) { }

  ngOnInit(): void {
  }

  async logout() {
    await this.loginService.logout();
    await this.router.navigate(['/login']);
  }
}
