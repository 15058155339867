import { Injectable } from '@angular/core';

export interface Session{
  accessToken: string;
  exp: number;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  saveSession(session) {
    try {
      sessionStorage.setItem('session', JSON.stringify(session));
    } catch (err) {
      console.log(err);
    }
  }

  getSession() {
    return JSON.parse(sessionStorage.getItem('session'));
  }

  clearSessionStorage() {
    sessionStorage.clear();
  }
}
