import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-in-details',
  templateUrl: './in-details-buy-order-modal.component.html',
  styleUrls: ['./in-details-buy-order-modal.component.scss']
})
export class InDetailsBuyOrderModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<InDetailsBuyOrderModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }
}
