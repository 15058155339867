import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../core/services/login.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  hide = true;

  constructor(private formBuilder: FormBuilder,
              private loginService: LoginService,
              private router: Router) { }

  ngOnInit(): void {
    this.buildLoginForm();
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async login() {
      if (this.loginForm.valid) {
        const response = await this.loginService.login(this.loginForm.controls.user.value, this.loginForm.controls.password.value, '');
        if (!response?.error) {
          await this.router.navigate(['/dashboard']);
        }
        if (response?.error?.code === 401) {
          this.loginForm.controls.password.setErrors({error_key: true});
        }
      }
  }
}
