import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BinsService {

  constructor(private httpClient: HttpClient) { }

  async getAll(body): Promise<any> {
    const headers = {
      'Content-Type': 'application/json'
    };
    return this.httpClient.get(environment.baseUrl + '/api/v1/forklift/bins', { headers }).toPromise<any>();
  }
}
