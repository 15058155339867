<app-header-modal [color]="'alert'" [title]="'Pedido N° 1002'">
  <div class="right" status>
    <app-status-header-modal [icon]="'spinner-alt'" [color]="'in'" [status]="'Aguardando NFe'"></app-status-header-modal>
    <i class="uil uil-times" (click)="close()"></i>
  </div>
</app-header-modal>

<mat-tab-group mat-align-tabs="center">
  <mat-tab label="Ordem de compra">
    <app-in-details-buy-order [order]="data"></app-in-details-buy-order>
  </mat-tab>
  <mat-tab label="Comentários">
    <app-in-details-comments></app-in-details-comments>
  </mat-tab>
</mat-tab-group>
