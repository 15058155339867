<div class="entry columns-group" [ngClass]="{'divergent': nfe.status === 'DIVERGENT' || nfe.status === 'BLOCKED'}">
  <div class="oc">
    {{nfe.orderOc}}
  </div>
  <div class="in-timestamp">
    {{ orderDate }}
  </div>
</div>
<div class="nfe-entry columns-group" [ngClass]="{'divergent': nfe.status === 'DIVERGENT' || nfe.status === 'BLOCKED'}">
  <div class="nfe">
    18.846-1
  </div>
  <div class="nfe-timestamp">
    {{ nfeDate }}
  </div>
</div>
<div class="document column">
  {{ nfe.document }}
</div>
<div class="name column">
  XPTO Acessórios LTDA
</div>
<div class="status column">
  <div  *ngIf="nfe.status === 'SUCCESS'" class="success-background">
    <i class="uil uil-check"></i>
  </div>
  <div *ngIf="nfe.status === 'DIVERGENT'" class="alert-background">
    <i class="uil uil-times"></i>
  </div>
  <div *ngIf="nfe.status === 'BLOCKED'" class="alert-background">
    <i class="uil uil-padlock"></i>
  </div>
  <img *ngIf="nfe.status === 'WAITING_COMPARISON'" src="assets/icons/spinner-check.svg">
  <img *ngIf="nfe.status === 'WAITING_CORRECTION'" src="assets/icons/spinner-edit.svg">
</div>
<div class="messages column">
  <i *ngIf="!nfe.commentsQty" class="uil uil-comment"></i>
  <i *ngIf="nfe.commentsQty" class="uil uil-comment-lines"></i>
  <span>{{ nfe.commentsQty }}</span>
</div>

