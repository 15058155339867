<div class="body">
  <div class="left-content">
    <button mat-button>
      <i class="uil uil-subject" style="font-size: 35px; color: #FFFFFF"></i>
    </button>
    <img alt="" src="">
  </div>
  <div class="right-content">
    <img *ngIf="user" src="" alt="">
    <i *ngIf="!user" class="uil uil-user-square" style="font-size: 50px; color: #FFFFFF"></i>
    <div class="user">
      <p>Marcelo da Silva</p>
      <small>Operador C13</small>
    </div>
    <button mat-button (click)="logout()">
      <i class="uil uil-signout" style="font-size: 30px; color: #FFFFFF"></i>
    </button>
  </div>
</div>
