import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-provider-contact',
  templateUrl: './provider-contact.component.html',
  styleUrls: ['./provider-contact.component.scss']
})
export class ProviderContactComponent implements OnInit {

  @Input() provider;

  constructor() { }

  ngOnInit(): void {
  }

}
