  <div class="oc column">
    {{order.oc}}
  </div>
  <div class="in-timestamp column">
    {{ date }}
  </div>
  <div class="document column">
    {{ order.document }}
  </div>
  <div class="name column">
    {{ order.corporateName }}
  </div>
  <div class="status column">
    <img src="/assets/icons/spinner-file.svg" alt="">
  </div>
  <div class="messages column">
    <i *ngIf="order.commentsQty === 0" class="uil uil-comment"></i>
    <i *ngIf="order.commentsQty !== 0" class="uil uil-comment-lines"></i>
    <span>{{ order.commentsQty }}</span>
  </div>
